import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { firstValueFrom } from 'rxjs';
import { LocalizedDatePipe } from './shared/pipes/localized-date-pipe';
import { MaskitoModule } from '@maskito/angular';
import { SharedModule } from './shared/shared.module';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import {
  createErrorHandler,
  init as sentryAngularInit,
  routingInstrumentation,
  TraceService,
} from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { environment } from 'src/environments/environment';
import sentryConfig from '../../sentry.config.json';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StorageService } from './services/storage.service';
import { FleetMenuComponent } from './fleet/components/fleet-menu/fleet-menu.component';

function appInitializerFactory(translateService: TranslateService) {
  return () => {
    const defaultLang = 'de';
    translateService.setDefaultLang(defaultLang);
    return firstValueFrom(translateService.use(defaultLang));
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

Sentry.init(
  {
    dsn: environment.sentryDsn,
    enabled: environment.sentryEnabled,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', environment.movacarProUrl],
        routingInstrumentation: routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    release: sentryConfig.commit,
    dist: sentryConfig.dist,
    environment: environment.environmentName,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  },
  sentryAngularInit
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MaskitoModule,
    IonicStorageModule.forRoot({
      name: 'movacar-pro',
    }),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FleetMenuComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, TraceService, StorageService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'de' },
    LocalizedDatePipe,
    SafeUrlPipe,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
