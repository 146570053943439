export const environment = {
  production: true,
  environmentName: 'production',
  carmovaApiUrl: 'https://carmova-api-production-2e4g646uda-ew.a.run.app',
  sentryDsn:
    'https://a4fe0e33952c017a218d52d9c9eda335@o1097620.ingest.us.sentry.io/4506982529892353',
  sentryEnabled: true,
  enableServiceWorker: true,
  transactionMinPicture: 4,
  movacarProUrl: 'https://movacarpro.com/',
  vehicleInspectionUrl:
    'https://vehicle-inspection-production-2e4g646uda-ew.a.run.app',
  vehicleInspectionClientKey: 'TQuRMzOqOThTRmjl',
  vehicleInspectionClientSecret: 'AK9uiBb3Qd8Rnfc44EJDmkynogFe7vyG',
  firebaseConfig: {
    apiKey: 'AIzaSyCTCtmKyBqiMNUBn84UzeRoIhq_PKO-cP0',
    authDomain: 'carmova.firebaseapp.com',
    projectId: 'carmova',
    storageBucket: 'carmova.firebasestorage.app',
    messagingSenderId: '480117496117',
    appId: '1:480117496117:web:537199eb61136fcfea147c',
    measurementId: 'G-NPR7BPE3H6',
  },
  monkeyPdfConfig: {
    createMonkeyPdfUrl: 'https://api.pdfmonkey.io/api/v1/documents',
    downloadMonkeyPdfUrl: 'https://api.pdfmonkey.io/api/v1/document_cards',
    templateId: 'D5B5B775-CFCE-4727-8CD3-FC1A2FE270D0',
  },
  zoho: {
    salesIQ: {
      ios: {
        appKey:
          'nyPRcpxHd4gXznosJGJZ5MBPaaa42QEIBeGeNOwqIzc%2FV%2BBw7jD5uqFM8qYyYmg%2F_eu',
        accessKey:
          'eNeAjMiuSCF9Qg5GRscSNbJl8hu8Pw4MLPVri1Fn%2B3WWDiVRXgLm%2BzpUu08dGZpdxCbQkgjkKpNk42vOb9TBXIAAOeSew%2B7VVFKCVBv1iRJN2kFqlWx1ZCPwVNbg7ejK',
      },
      android: {
        appKey:
          'nyPRcpxHd4gXznosJGJZ5MBPaaa42QEIBeGeNOwqIzc%2FV%2BBw7jD5uqFM8qYyYmg%2F_eu',
        accessKey:
          'eNeAjMiuSCFJvf%2Ftsm3zHh1lgY6TDGBH09QgngWzuEgUqLPpNaIjuDlQ5XPfzMFeCsTqTjZ6oPOzqs4doyJ58P3ps9DFP8Hp2tAvbhP9%2B5z8VPL%2B%2BNpEVDVN9U4EIJ5e',
      },
    },
  },
  salesforceUrl: 'https://movacar.my.salesforce-sites.com/services/apexrest',
};
